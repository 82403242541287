$(function () {
  $('button.realtime-auth0-submit').on('click', function () {
    const $form = $(this).closest('form');
    const spinner = '<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>&nbsp;Processing request...';

    $(this)
      .empty()
      .attr('disabled', 'disabled')
      .removeClass('btn-primary')
      .addClass('btn-secondary')
      .append(spinner);

    $form.submit();
  });
});
